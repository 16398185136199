import React from "react";
import HTMLReactParser from "html-react-parser";
import Img from "gatsby-image";

const PrivacyPage = (props) => {
  //PROPS
  const {
    data: {
      page: {
        privacidade: { blocoDeTexto, imagem }
      }
    }
  } = props;

  return (
    <main className="main main--nopad">
      <section className="section-banner">
        <Img
          fluid={imagem?.localFile?.childImageSharp.fluid}
          alt={imagem?.altText}
        />
      </section>

      <section className="section" style={{ paddingBottom: "4rem" }}>
        <div className="container">
          {blocoDeTexto.map((item, index) => {
            return (
              <>
                <h3 style={{ display: "flex", paddingTop: "40px" }}>{item.titulo}</h3>
                {item.texto && HTMLReactParser(item.texto)}
              </>
            );
          })}
        </div>
      </section>
    </main>
  );
};

export const query = graphql`
  query Privacy($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      privacidade {
        blocoDeTexto {
          texto
          titulo
        }
        imagem {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default PrivacyPage;
